import { useContext, useState, createContext, useEffect, useRef } from "react";
import { update, getByID } from "../API/queries";
import { AuthContext } from "./AuthProvider";
import { CallsContext } from './CallsProvider'
import { ConfigContext } from "./ConfigProvider";
import { toast } from "react-toastify";
import { ratingInput } from "../services/ratingInput";

export const AttendanceContext = createContext()

export const AttendanceProvider = ({children}) => {

    const { user, setUser, userStatus, desk, updateOperatingStatus } = useContext(AuthContext)
    const { queue, refetchCalls } = useContext(CallsContext)

    const [previousCall, setPreviousCall] = useState(null)
    const [actualCall, setActualCall] = useState(null)
    const [nextCall, setNextCall] = useState(null)
    const [secondCall, setSecondCall] = useState(null)

    const [status, setStatus] = useState('idle')
    const [calling, setCalling] = useState(false)

    const intervalRef = useRef()

    const [allowRatingStatus, setAllowRatingStatus] = useState('idle')
    const allowRatingTimerRef = useRef()

    const userRef = useRef(user)

    const [lastActivity, _setLastActivity] = useState(0)
    const lastActivityRef = useRef(lastActivity)
    const setLastActivity = (data) => {
        _setLastActivity(data)
        lastActivityRef.current = data
    }
    const lastActivityTimer = useRef(0)
    const inactivityReported = useRef(false)

    useEffect(() => {
        userRef.current = user
    },[user])

    const { permissions, notifications } = useContext(ConfigContext)
    const { back_call, ring_alert } = permissions
    const { attendantOut } = notifications

    const attendantOutRef = useRef(attendantOut)

    useEffect(() => {
        attendantOutRef.current = attendantOut
    },[attendantOut])
    
    const alertOpts = {
        operation: 'alert',
        decrement: true,
        increment: false,
        returnDocument: 'before',
        alert: true,
        notify: 'alert',
        options: {
            index: null,
            status: 'alerted',
            alerted_at: Date.now(),
            attended_at: null,
            finished_at: null,
            desk: desk?.name,
            desk_id: desk?._id,
            attendant_id: user?._id,
        }
    }
    
    const attendOpts = {
        operation: 'attend',
        decrement: false,
        increment: false,
        returnDocument: 'after',
        alert: false,
        options: {
            status: "attended",
            attended_at: Date.now(),
        }
    }
    
    const ringOpts = {
        operation: 'ring',
        decrement: false,
        increment: false,
        returnDocument: 'after',
        alert: true,
        options: {}
    }
        
    const skipOpts = {
        operation: 'skip',
        decrement: false,
        increment: false,
        returnDocument: 'after',
        alert: false,
        options: {
            status: "unattended"
        }
    }

    const finishOpts = {
        operation: 'finish',
        decrement: false,
        increment: false,
        returnDocument: 'before',
        alert: false,
        options: {
            status: "finished", 
            finished_at: Date.now()
        }
    }

    const restoreNextOpts = {
        operation: 'restore_next',
        decrement: false,
        increment: true,
        returnDocument: 'after',
        alert: false,
        options: {
            index: 0,
            status: "waiting",
            alerted_at: null,
            desk_id: null,
            attendant_id: null
        }
    }

    const reportInactivity = () => {
        if(inactivityReported.current) return

        update('attendant/inactivity', userRef.current._id, {time: attendantOutRef.current}).then((res) => {
            if(res.status === 200) inactivityReported.current = true
        })
    }

    useEffect(() => {
        lastActivityTimer.current = setInterval(() => {
            if(Date.now() - lastActivityRef.current > attendantOutRef.current * 60000) reportInactivity()
        }, 5000);

        //return () => clearInterval(lastActivityTimer.current)
    },[])

    useEffect(() => {
        setLastActivity(Date.now())
        inactivityReported.current = false

        // if(status === 'finished'){
        //     setAllowRatingStatus('waiting')

        //     allowRatingTimerRef.current = setTimeout(() => {
        //         setAllowRatingStatus('idle')
        //     }, 60 * 1000);
        // }
    },[status])

    // useEffect(() => {
    //     if(allowRatingStatus === 'waiting'){
    //         document.body.addEventListener('keydown', handleRatingInput)
    //     }
    //     else document.body.removeEventListener('keydown', handleRatingInput)

    //     return () => document.body.removeEventListener('keydown', handleRatingInput)
    // },[allowRatingStatus])

    // const handleRatingInput = (e) => {

    //     const rating = ratingInput(e, userRef.current)

    //     if(!rating) return

    //     update('attendant', userRef.current._id, {rating: rating}).then(res => {
    //         setUser(res.data.user)
    //         setAllowRatingStatus('done')
    //         clearTimeout(allowRatingTimerRef.current)
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }

    const checkUserStatus = () => {
        if(userStatus === 'paused') {
            toast.error('Você está com o atendimento pausado. Inicie-o antes de prosseguir', {containerId: 'alert'})
            return 1
        }
        return 0
    }
    
    const doAlert = async (id) => {
        return await update('calls', id, alertOpts)
    }
    
    const doRing = async (id) => {
        return await update('calls', id, ringOpts)
    }
    
    const doAttend = async (id) => {
        return await update('calls', id, attendOpts)
    }
    
    const doSkip = async (id) => {
        return await update('calls', id, skipOpts)
    }

    const doFinish = async (id) => {
        return await update('calls', id, finishOpts)
    }

    const doRestoreNext = async (id) => {
        return await update('calls', id, restoreNextOpts)
    }

    const checkNextCall = async (id) => {
        let valid = 1
        
        await getByID('callsById', id).then((res) => {
            if(res.data.status === 'waiting') valid = 0
        })

        return valid
    }

    const getNextCalls = () => {
        if(queue === null || queue.length === 0) {
            setNextCall(null)
            setSecondCall(null)
        }
        else if(queue.length === 1) {
            setNextCall(queue[0])
            setSecondCall(null)
        }
        else if(queue.length > 1) {
            setNextCall(queue[0])
            setSecondCall(queue[1])
        }
    }

    useEffect(() => {
        getNextCalls()
    },[queue])

    const shiftCalls = () => {
        setPreviousCall(actualCall)
        setActualCall(nextCall)
        setNextCall(secondCall)
    }

    const startRepeatInterval = (call) => {
        intervalRef.current = setInterval(() => {
            doRing(call._id)
        }, 20000);
    }

    const stopRepeatInterval = () => {
        clearInterval(intervalRef.current)
    }

    const callNext = async (call) => {

        if(checkUserStatus()) return

        setCalling(true)

        if(await checkNextCall(call._id)) {
            toast.error('Essa senha já foi chamada por outro atendente', {containerId: 'alert'})
            refetchCalls()
            setCalling(false)
            return
        }

        if(status === 'waiting'){
            doSkip(actualCall._id)
        }

        doAlert(call._id).then(() => {
            setStatus('waiting')
            //startRepeatInterval(call)
            shiftCalls()
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setCalling(false)
        })

        updateOperatingStatus('waiting')
    }

    const callRandom = (call) => {

        if(checkUserStatus()) return

        setActualCall(call)

        if(status === 'waiting'){
            doSkip(actualCall._id)
        }

        doAlert(call._id).then(() => {
            setStatus('waiting')
            startRepeatInterval(call)
            setPreviousCall(actualCall)
        }).catch(err => {
            console.log(err)
        })

        updateOperatingStatus('waiting')
    }

    const callPrevious = async () => {

        if(checkUserStatus()) return

        if(!back_call) return toast.error('Você não possui permissão para chamar senhas anteriores', {containerId: 'alert'})

        if(status === 'attending') return toast.error('Você deve finalizar o atendimento antes de chamar outra senha', {containerId: 'alert'})

        if(previousCall === null) return toast.error('Não há nenhuma senha anterior para ser chamada', {containerId: 'alert'})

        if(status === 'waiting') {
            await doRestoreNext(actualCall._id).then((res) => {
                setNextCall(res.data)
                setPreviousCall(null)
            })
        } else if (status === 'finished') setPreviousCall(actualCall)

        await doAlert(previousCall._id)
        
        setStatus('waiting')
        setActualCall(previousCall)

        updateOperatingStatus('waiting')
    }

    const startAttendance = (call) => {

        if(checkUserStatus()) return
        
        doAttend(call._id).then(() => {
            setStatus('attending')
            stopRepeatInterval()
        }) 

        updateOperatingStatus('attending')
    }

    const finishAttendance = (call) => {
        
        if(checkUserStatus()) return

        doFinish(call._id).then(() => {
            setStatus('finished')
        })

        updateOperatingStatus('idle')
    }

    const ring = (call) => {
        if(!ring_alert) return toast.error('Você não possui permissão para soar o alerta', {containerId: 'alert'})
        doRing(call._id)
    }
    
    return(
        <AttendanceContext.Provider value={{previousCall, actualCall, nextCall, status, calling, setStatus, callNext, callRandom, startAttendance, finishAttendance, callPrevious, ring, allowRatingStatus}}>
            {children}
        </AttendanceContext.Provider>
    )
}

// STATUS STRINGS ---------------------------------------------------------------------------------------------------------------
// >>> 'idle' - status inicial, não foi chamado nenhuma senha nem iciando um atendimento
// >>> 'waiting' - uma senha foi chamada e o guichê está esperando a pessoa comparecer. É possível chamar outra senha
// >>> 'attending' - a pessoa compareceu no guichê e o atendimento foi iniciado. Não pode chamar outra senha antes de finalizar
// >>> 'finished' - o atendimento foi finalizado, pronto para chamar uma nova senha
// -------------------------------------------------------------------------------------------------------------------------------
