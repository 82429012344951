import styled from "styled-components";
import { BsCheck, BsX } from 'react-icons/bs'

const YesButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 4rem;
    border: 1px solid #00C853;
    border-radius: 1rem;
    background-color: white;
    cursor: pointer;
    margin: 0.5rem;

    svg{
        color: #00C853;
        font-size: 2rem;
    }

    &:hover{
        background-color: #00C853;

        svg{
            color: white;
        }
    }
`

const NoButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2.5rem;
    border: 1px solid #F34236;
    border-radius: 1rem;
    background-color: white;
    cursor: pointer;
    margin: 0.5rem;

    svg{
        color: #F34236;
        font-size: 2rem;
    }

    &:hover{
        background-color: #F34236;

        svg{
            color: white;
        }
    }
`

export const Yes = ({onClick}) => {
    return(
        <YesButton onClick={() => onClick()}>
            <BsCheck/>
        </YesButton>
    )
}

export const No = ({onClick}) => {
    return(
        <NoButton onClick={() => onClick()}>
            <BsX/>
        </NoButton>
    )
}