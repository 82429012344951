import api from './api' 

const headers = () => {
    return {
        headers: {
            authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('token'))
        }
    }
}

export const getAll = async (route) => {
    return await api.get(route, headers())
}

export const getByID = async (route, id) => {
    return await api.get(`${route}/${id}`, headers())
}

export const update = async (route, id, data) => {
    return await api.put(`${route}/${id}`, data, headers())
}

export const connectAttendant = async (data) => {
    return await api.post('attendant/connect', data, headers())
}

export const disconnectAttendant = async (data) => {
    return await api.post('attendant/disconnect', data, headers())
}

export const pauseAttendant = async (data) => {
    return await api.post('attendant/pause', data, headers())
}

export const unpauseAttendant = async (data) => {
    return await api.post('attendant/unpause', data, headers())
}
