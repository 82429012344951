import styled from "styled-components";
import { BiBell } from 'react-icons/bi'
import { BsPlay, BsStop } from 'react-icons/bs'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

const Wrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: -1.25rem;
`

const LeftBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 2.5rem;
    border: none;
    border-radius: 1.25rem 0 0 1.25rem;
    background-color: ${props => props.status ? '#F9A626' : '#90A4AE'};
    cursor: pointer;

    svg{
        color: white;
        font-size: 22px;
    }

    &:hover{
        background-color: ${props => props.status && '#FB9800'};
    }

    &:active{
        background-color: ${props => props.status && '#F78d00'};
        color: white;
    }
`

const RightBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 2.5rem;
    border: none;
    border-radius: 0 1.25rem 1.25rem 0;
    background-color: ${props => props.status === 'waiting' ? '#26A69A' : props.status === 'attending' ? '#EF5350' : '#78909C'};
    cursor: pointer;

    svg{
        color: white;
        font-size: 28px;
    }

    &:hover{
        background-color: ${props => props.status === 'waiting' ? '#00897B' : props.status === 'attending' ? '#E53935' : '#78909C'};
    }

    &:active{
        background-color: ${props => props.status === 'waiting' ? '#00695C' : props.status === 'attending' ? '#C62828' : '#78909C'};
        color: white;
    }
`

function ControlButtons({status, onRing, onStart, onFinish}){
    return(
        <Wrapper>
            <Tooltip theme="light" arrow delay={800} hideDelay={0} position={'top'} html={'Soar alerta do Painel'}>
                <LeftBtn status={status === 'waiting'} onClick={() => onRing()}>
                    <BiBell/>
                </LeftBtn>
            </Tooltip>
            {
                status === 'waiting' ? 
                <Tooltip theme="light" arrow delay={800} position={'top'} html={'Iniciar atendimento'}>
                    <RightBtn status={status} onClick={() => onStart()}>
                        <BsPlay/>
                    </RightBtn>
                </Tooltip>
                :
                status === 'attending' ? 
                <Tooltip theme="light" arrow delay={800} position={'top'} html={'Finalizar atendimento'}>
                    <RightBtn status={status} onClick={() => onFinish()}>
                        <BsStop/>
                    </RightBtn>
                </Tooltip>
                :
                (status === 'finished' || status === 'idle') &&
                <Tooltip theme="light" arrow delay={800} position={'top'} html={'Iniciar atendimento'}>
                    <RightBtn status={status}>
                        <BsPlay/>
                    </RightBtn>
                </Tooltip>
            }
        </Wrapper>
    )
}

export default ControlButtons