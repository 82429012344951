import { createContext, useState, useCallback, useContext, useEffect, useRef } from 'react'
import { getAll } from '../API/queries'
import { useIntervalAsync } from '../services/useIntervalAsync.ts'
import { AuthContext } from './AuthProvider'
import { filterCallsByStatus } from '../services/stats'
import { ConfigContext } from './ConfigProvider'

export const CallsContext = createContext({})

export const CallsProvider = ({children}) => {

    const { permissions } = useContext(ConfigContext)
    const { authenticated, place, desk } = useContext(AuthContext)

    const authRef = useRef(authenticated)

    const [calls, setCalls] = useState(null)
    const [queue, setQueue] = useState(null)

    const callsLoadedState = useRef(false)
    const placeRef = useRef(place)

    const filterCalls = (calls) => {      
        const _calls = calls.filter(call => call.place === placeRef.current)
        setCalls(_calls)
    }

    const refetchCalls = async () => {
        await getAll('calls/todays').then((res) => {
            filterCalls(res.data)
            callsLoadedState.current = true
        }).catch((err) => {
            console.log(err)

            if(err.response.data.error === 'Invalid token') {
                sessionStorage.clear()
                window.location.replace('https://login.phila.com.br')
            }
        })    
    }
    
    const updateData = useCallback(async () => { 
        if(!authRef.current) return

        await getAll('calls/todays').then((res) => {
            filterCalls(res.data)
            callsLoadedState.current = true
        }).catch((err) => {
            console.log(err.response.data.error)

            if(err.response.data.error === 'Invalid token') {
                sessionStorage.clear()
                window.location.replace('https://login.phila.com.br')
            }
        })        
    },[])

    useIntervalAsync(updateData, 5000);

    useEffect(() => {
        authRef.current = authenticated
    },[authenticated])

    const handleQueue = () => {

        const sortByIndex = (a,b) => {
            if(a.index > b.index) return 1
            else return -1
        }

        const waitingCalls = filterCallsByStatus(calls, 'waiting')
        const queue = waitingCalls.filter(call => desk?.pw_types.includes(call.type) && desk?.services.includes(call.service)).sort(sortByIndex)
        
        if(!permissions.attend_others) return setQueue(queue)
        
        const otherCalls = waitingCalls.filter(call => !desk?.pw_types.includes(call.type) || !desk?.services.includes(call.service)).sort(sortByIndex)

        queue.push(...otherCalls)
        
        setQueue(queue)
    }

    useEffect(() => {
        if(calls !== null) handleQueue()
        else setQueue(null)
    },[calls])

    useEffect(() => {
        if(place !== null) placeRef.current = place
    },[place])
    
    return(
        <CallsContext.Provider value={{calls, queue, refetchCalls}}>
            {children}
        </CallsContext.Provider>
    )
}