import { Icon, Position } from '../../../layout'
import { BiCommentDots } from 'react-icons/bi'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

export default function AnnotationIcon({color, onShow}){

    return(
        <Position top={2} right={2}>
            <Tooltip theme="light" arrow delay={800} position={'top'} html={'Visualizar o comentário'}>
                <Icon pointer size={1.5} color={color} onClick={() => onShow()}>
                    <BiCommentDots/>
                </Icon>
            </Tooltip>
        </Position>
    )
}