import { Column, Text, Dash } from '../../layout'
import StopWatch from './StopWatch'

function MainPw({call, status}){
    return(
        <Column width={20} align={'center'} justify={'center'} bottom={1.5} height={'100%'}>
            <Text>Atual</Text>
            {
                call !== null ? 
                <Text size={2} weight={800} color={call?.prior ? '#F9A626' : '#00BFA5'}>{call?.password}</Text>
                :
                <Dash/>
            }
            {
                status === 'finished' ? 
                <Text size={1.1} weight={500}>Finalizado</Text>
                :
                status === 'attending' ? 
                <StopWatch run={true}/>
                :
                status === 'waiting' &&
                <Text size={1.1} weight={500}>Aguardando...</Text>
            }
        </Column>
    )
}

export default MainPw