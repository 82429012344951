import { Row, Column, Text, Icon } from '../../../layout/'
import { BsX, BsArrowRightShort } from 'react-icons/bs'
import { ConfigContext } from '../../../contexts/ConfigProvider'
import { useContext } from 'react'
import { toast } from 'react-toastify';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';

function QueueTableRow({call, index, onCancel, onCallRandom}){
    
    const { permissions } = useContext(ConfigContext)
    const { cancel_call, random_call } = permissions

    const handleCancel = () => {
        if(!cancel_call) return toast.error('Você não tem permissão para cancelar senhas', {containerId: 'alert'})
        onCancel(call)
    }

    const handleCallRandom = () => {
        if(!random_call) return toast.error('Você não tem permissão para chamar senhas aleatórias', {containerId: 'alert'})
        onCallRandom(call)
    }

    const handleCallRandomError = () => {
        toast.error('Você deve chamar a próxima senha clicando em Próximo na barra de controle')
    }

    return(
        <Row height={'4rem'} align={'center'} borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#eee'} left={1} hoverColor={'#eee'}> 
            <Column width={10}>
                <Text weight={600} color={call.prior ? '#F9A626' : '#00BFA5'}>{call.password}</Text>
            </Column>
            <Column width={13}>
                <Text align={'center'} wrap={'nowrap'}>{call.service}</Text>
            </Column>
            <Column width={13}>
                <Text align={'center'}>{call.type}</Text>
            </Column>
            <Column align={'center'} width={15}>
                <Text>{new Date(call.created_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</Text>
            </Column>
            <Column align={'center'} width={12}>
                {
                    index === 0 ?
                    <Text weight={600} color={'#FB9800'}>Próxima</Text>
                    : 
                    <Text weight={600}>{index+'°'}</Text>
                }
            </Column>
            <Column align={'center'} width={20}>
                <Text weight={600}>
                    {Math.floor((Date.now() - call.created_at)/60000)+' minutos'}
                </Text>
            </Column>
            <Column align={'center'} width={15}>
                <Row justify={'center'}>
                    <Tooltip theme="light" arrow delay={800} position={'top'} html={'Cancelar está senha'}>
                        <Icon pointer size={2} color={'#F34236'} onClick={() => handleCancel(call)}>
                            <BsX/>
                        </Icon>
                    </Tooltip>
                    {
                        index === 0 ? 
                        <Icon pointer size={2.1} color={'#aaa'} onClick={() => handleCallRandomError()}>
                            <BsArrowRightShort/>
                        </Icon>
                        :
                        <Tooltip theme="light" arrow delay={800} position={'top'} html={'Chamar esta senha'}>
                            <Icon pointer size={2.1} color={'#FB9800'} onClick={() => handleCallRandom(call)}>
                                <BsArrowRightShort/>
                            </Icon>
                        </Tooltip>
                    }
                </Row>
            </Column>
        </Row>
    )
}

export default QueueTableRow