import { Section, Avatar, Text, Row, Column, Icon } from '../layout/Layout'
import { AuthContext } from '../contexts/AuthProvider'
import { useContext, useEffect } from 'react'
import  { BsArrowRight, BsPause, BsPlay } from 'react-icons/bs'
import  { IoExitOutline } from 'react-icons/io5'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
// import TerminalConnectedIcon from './ratingTerminal/TerminalConnectedIcon'

function User({onLogout, doLogout}){

    const { user, desk, place, logout, pause, unpause } = useContext(AuthContext)

    useEffect(() => {
        if(doLogout) logout(desk)
    },[doLogout])
    
    return(
        <Section color={'#37474F'} width={17} height={100} align={'center'} direction={'column'}>
            {/* <TerminalConnectedIcon/> */}
            <Row align={'center'} height={70} left={10}>
                <Column width={'fit-content'}>
                    <Avatar size={50} img={user?.avatar_url}/>
                </Column>
                <Column>
                    <Text size={12} color={'white'} weight={600}>{user?.name}</Text>
                    <Row align={'center'}>
                        <Text size={11} weight={300} color={'white'}>{place}</Text>
                        <Icon size={14} color={'white'} margin={'0px 3px'}>
                            <BsArrowRight/>
                        </Icon>
                        <Text size={11} weight={300} color={'white'}>{desk?.name}</Text>
                    </Row>
                </Column>
            </Row>
            <Row align={'center'} height={30} justify={'flex-end'} color={user?.status === 'active' ? '#26A69A' : '#FFC107'}>
                <Column height={100} justify={'center'} paddingLeft={10}>
                    {
                        user?.status === 'active' ? 
                        <Text size={11} weight={600} color={'#1B5E20'}>Em Atendimento</Text>
                        :
                        <Text size={11} weight={600} color={'#E65100'}>Em Pausa</Text>
                    }
                </Column>
                <Column>
                    <Row justify={'flex-end'}>
                        {
                            user?.status === 'active' ? 
                            <Tooltip theme="light" arrow delay={800} position={'bottom'} html={'Fazer uma pausa no atendimento'}>
                                <Icon pointer size={22} color={'white'} margin={'2px 3px 0 0'} hoverColor={'#FB9800'} onClick={() => pause(desk)}>
                                    <BsPause/>
                                </Icon>
                            </Tooltip>
                            :
                            <Tooltip theme="light" arrow delay={800} position={'bottom'} html={'Voltar a atender'}>
                                <Icon pointer size={22} color={'white'} margin={'2px 3px 0 0'} hoverColor={'#00BFA5'} onClick={() => unpause(desk)}>
                                    <BsPlay/>
                                </Icon>
                            </Tooltip>
                        }
                        <Tooltip theme="light" arrow delay={800} position={'bottom'} html={'Sair do Painel de Atendimento'}>
                            <Icon pointer size={19} color={'white'} margin={'2px 3px 0 0'} hoverColor={'#B2DFDB'} onClick={() => onLogout()}>
                                <IoExitOutline/>
                            </Icon>
                        </Tooltip>
                    </Row>
                </Column>
            </Row>
        </Section>
    )
}

export default User