import { createContext, useContext, useEffect, useRef, useState } from "react";
import { NotifToast, getIcon, getType } from '../components/notification/NotifToast'
import { toast } from 'react-toastify'
import { ConfigContext } from "./ConfigProvider";
import { AuthContext } from './AuthProvider'
import alert23 from '../content/alerts/alert-23.mp3'
import { SocketContext } from "./SocketProvider";

export const NotifContext = createContext()

export const NotifProvider = ({children}) => {

    const { socketNotification } = useContext(SocketContext)
    
    const { notifications: notifConfig } = useContext(ConfigContext)
    const { business, user } = useContext(AuthContext)

    const alert = new Audio(alert23)

    const [notifications, _setNotifications] = useState([])
    const notificationsRef = useRef()
    const setNotifications = (data) => {
        notificationsRef.current = data
        _setNotifications(data)
    }
    
    const [isNew, setIsNew] = useState(false)
    
    const [mute, _setMute] = useState(false)
    const muteRef = useRef(false)
    const setMute = (data) => {
        muteRef.current = data
        _setMute(data)
    }

    const notifConfigRef = useRef()
    const businessId = useRef()
    const userId = useRef()

    useEffect(() => {
        
        const recoveredNotifs = sessionStorage.getItem('notifs')
        
        if(recoveredNotifs) setNotifications(JSON.parse(recoveredNotifs))
        
    },[])

    useEffect(() => {
        if(socketNotification && socketNotification?.userId === businessId.current) notify(socketNotification)
    },[socketNotification])

    useEffect(() => {
        if(notifConfig) notifConfigRef.current = notifConfig
    },[notifConfig])

    useEffect(() => {
        if(business) businessId.current = business._id
    },[business])

    useEffect(() => {
        if(user) userId.current = user._id
    },[user])

    const checkConfig = (event) => {
        if(event === 'callAlerted') return 0
        return notifConfigRef.current[event]
    }

    const playAlert = () => {
        if(muteRef.current) return
        alert.play()
    }

    const addNotification = (notif) => {
        const _notif = [...notifications]

        _notif.unshift(notif)

        sessionStorage.setItem('notifs', JSON.stringify(_notif))
        setNotifications(_notif)
    }

    const removeNotification = (id) => {
        const _notif = [...notifications]
        let index = _notif.findIndex(temp => temp.id === id)

        _notif.splice(index, 1)

        sessionStorage.setItem('notifs', JSON.stringify(_notif))
        setNotifications(_notif)
    }

    const attendantOutIsUserMessage = (notif) => {
        if(notif.id === userId.current) return `ATENÇÃO: Você esta a mais de ${notifConfigRef.current.attendantOut} minutos sem atender`
        return notif.message
    }

    const notify = (notification) => {
        
        const { id, type, event } = notification
        
        if(!checkConfig(event)) return

        if(event === 'attendantOut') notification.message = attendantOutIsUserMessage(notification)
        
        toast(<NotifToast notification={notification}/>, {
            containerId: 'notify', 
            icon: getIcon(type), 
            toastId: id, 
            type: getType(type)
        })
        
        playAlert()
        setIsNew(true)

        addNotification({id, type, message: notification.message, time: Date.now()})
    }

    return (
        <NotifContext.Provider value={{notifications, setNotifications, mute, setMute, isNew, setIsNew, removeNotification}}>
            {children}
        </NotifContext.Provider>
    )
}