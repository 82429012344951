import styled from "styled-components"
import { IoWarningOutline } from 'react-icons/io5'
import { BiLogIn, BiLogOut, BiX } from 'react-icons/bi'
import { RiUserShared2Line } from 'react-icons/ri'
import { useContext } from "react"
import { NotifContext } from "../../contexts/NotifProvider"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    background-color: ${props => props.urgent && '#FFEBEE'};

    #delete{
            opacity: 0;
    }

    &:hover{
        background-color: ${props => props.urgent ? '#FFCDD2' : '#f5f5f5'};

        #delete{
            opacity: 1;
            cursor: pointer;
        }
    }
`

const Header = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding-left: 10px;
    
    svg{
        font-size: 18px;
        margin-right: 5px;
        color: ${props => props.urgent ? '#E57373' : '#aaa'};

        &:hover{
            transform: scale(1.2);
            color: ${props => props.urgent ? '#EF5350' : '#888'};
        }

    }

    #orange{
        color: #FB9800;
    }

    #green{
        color: #00BFA5;
    }
    
    h1{
        font-weight: 500;
        color: ${props => props.urgent && '#E53935'};
    }
`

const Right = styled.span`
    display: flex;
    align-items: center;
    margin-left: auto;

    h1{
        font-weight: 300;
    }
`

const Message = styled.span`
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 5px 10px 15px 10px;
    color:  ${props => props.urgent && '#E53935'};
`

function NotifRow({data}){
    
    const { removeNotification } = useContext(NotifContext)
    
    return(
        <Container urgent={data.urgent}>
            <Header urgent={data.urgent}>
                {data.type === 'warning' && 
                    <>
                        <IoWarningOutline id={'orange'}/>
                        <h1>Aviso</h1>
                    </>
                }
                {data.type === 'login' && 
                    <>
                        <BiLogIn id={'green'}/>
                        <h1>Login</h1>
                    </>
                }
                {data.type === 'logout' && 
                    <>
                        <BiLogOut id={'green'}/>
                        <h1>Logout</h1>
                    </>
                }
                {data.type === 'attendance' && 
                    <>
                        <RiUserShared2Line id={'green'}/>
                        <h1>Atendimento</h1>
                    </>
                }
                <Right>
                    <h1>{new Date(data.time).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</h1>
                    <BiX id={'delete'} onClick={() => removeNotification(data.id)}/>
                </Right>
            </Header>
            <Message urgent={data.urgent}>
                {data.message}
            </Message>
        </Container>
    )
}

export default NotifRow