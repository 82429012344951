import { useContext } from 'react'
import { createContext, useEffect, useState } from 'react'
import { getAll } from '../API/queries'
import { AuthContext } from './AuthProvider'

export const DeskContext = createContext({})

export const DeskProvider = ({children}) => {

    const [desks, setDesks] = useState(null)
    const { authenticated } = useContext(AuthContext)

    const getDesks = () => {
        getAll('desk').then(res => {
            setDesks(res.data)
        })
    }

    useEffect(() => {
        if(authenticated) getDesks()
    },[authenticated])

    return(
        <DeskContext.Provider value={{desks}}>
            {children}
        </DeskContext.Provider>
    )
}