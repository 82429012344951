import Main from "./pages/main";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./contexts/AuthProvider";
import { ConfigProvider } from './contexts/ConfigProvider'
import { NotifProvider } from './contexts/NotifProvider'
import { CallsProvider } from "./contexts/CallsProvider";
import { AttendanceProvider } from "./contexts/AttendanceProvider";
import { StatsProvider } from "./contexts/StatsProvider";
import { DeskProvider } from './contexts/DeskProvider'
import { SocketProvider } from "./contexts/SocketProvider";
// import { RatingTerminalProvider } from "./contexts/RatingTerminalProvider";

function App() {

  return (
    <>
      <ConfigProvider>
        <AuthProvider>
          <SocketProvider>
            <NotifProvider>
              <CallsProvider>
                <AttendanceProvider>
                  <StatsProvider>
                    <DeskProvider>
                      {/* <RatingTerminalProvider> */}
                        <Main/>
                      {/* </RatingTerminalProvider> */}
                    </DeskProvider>
                  </StatsProvider>
                </AttendanceProvider>
              </CallsProvider>
            </NotifProvider>
          </SocketProvider>
        </AuthProvider>
      </ConfigProvider>
      <ToastContainer enableMultiContainer position="top-center" autoClose={5000} containerId={'alert'}/>
      <ToastContainer enableMultiContainer position="bottom-right" autoClose={5000} containerId={'notify'}/>
    </>
  )
}

export default App;
