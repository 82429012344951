import { ModalBox } from "./ModalBox";
import { Row, Text, Column } from '../../layout'
import { Yes, No } from '../buttons'

export default function LogoutConfirmation({onConfirm, onCancel}){

    return(
        <ModalBox>
            <Column align={'center'} height={'3rem'}>
                <Text size={1.1}>{'Deseja sair do painel e'}</Text>
                <Text size={1.1}>{'finalizar o seu atendimento?'}</Text>
            </Column>
            <Row justify={'center'} align={'center'} height={'3rem'}>
                <Yes onClick={() => onConfirm()}/>
                <No onClick={() => onCancel()}/>
            </Row>
        </ModalBox>
    )
}