import { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { Text } from '../../layout/Layout'

function StopWatch({run}) {
  
    const {seconds, minutes, hours, days, isRunning, start, pause, reset} = useStopwatch({});

    useEffect(() => {
        if(run) start()
    }, [run])


    return (
        <>
            <Text size={14} weight={500}>{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</Text>
        </>
    )
}

export default StopWatch