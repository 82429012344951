import { createContext, useContext, useEffect, useRef, useState } from "react";
import io from 'socket.io-client'
import { AuthContext } from "./AuthProvider";
import { useCallback } from "react";

export const SocketContext = createContext()

export const SocketProvider = ({children}) => {

    const { desk, forceDisconnect, user, authenticated } = useContext(AuthContext)
    const deskIdRef = useRef('')

    useEffect(() => {
        if(desk) deskIdRef.current = desk._id
    },[desk])

    const [socketNotification, setSocketNotification] = useState(null)

    const _forceDisconnect = useCallback(() => forceDisconnect(),[forceDisconnect])
    
    useEffect(() => {

        if(authenticated) {
            const socket = io.connect(process.env.REACT_APP_API_KEY, {
                query: {
                    clientType: 'attendant',
                    userId: user._id,
                    deskId: desk._id
                }
            })

            socket.on('notification', (data) => {
                setSocketNotification(data.notification)
                //socketNotification.current = data.notification
                //console.log(data.notification)
            })

            socket.on('manuallydisconnect', (data) => {
                if(data.id === deskIdRef.current) _forceDisconnect()
            })

            return () => {
                socket.off('notification')
                socket.off('manuallydisconnect')
            }
        }
        
    },[authenticated])

    return (
        <SocketContext.Provider value={{socketNotification}}>
            {children}
        </SocketContext.Provider>
    )
}