import { useRef, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import DoneCallsArrow from './DoneCallsArrow'
import DoneCallsBox from './DoneCallsBox'
import { CallsContext } from '../../../contexts/CallsProvider'
import PulseLoader from "react-spinners/PulseLoader"
import { Column, Row, Text } from '../../../layout'

const Container = styled.div`
    display: flex;
    background-color: white;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
`

const Display = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar{
        display: none;
    }
`

const Boxes = styled.div`
    display: flex;
    height: 100%;
    width: fit-content;
    margin-left: auto;
`

function DoneCallsDisplay({onShowAnnotation}){

    const { calls } = useContext(CallsContext)
    const [attendedCalls, setAttendedCalls] = useState(null)

    const [boxSize, setBoxSize] = useState(0)

    const displayRef = useRef('display')

    const scrollRight = () => {
        displayRef.current.scrollTo({
            left: 999999999,
            behavior: 'smooth',
        });
    }

    const handleScroll = (dir) => {
        if(dir === 'left') displayRef.current.scrollBy(-boxSize,0)
        if(dir === 'right') displayRef.current.scrollBy(boxSize,0)
    }

    const updateAttendedCalls = () => {
        const newCalls = calls?.filter(call => call.status === 'finished')

        if(attendedCalls === null) {
            setAttendedCalls(newCalls)
            return
        }

        if(newCalls.length === attendedCalls.length) return
        
        setAttendedCalls(newCalls)
    }

    useEffect(() => {
        scrollRight()
    },[attendedCalls])

    useEffect(() => {
        if(calls !== null) updateAttendedCalls()
    },[calls])

    const sortByTime = (a,b) => {
        if(a.attended_at > b.attended_at) return 1
        else return -1
    }

    const getBoxSize = () => {
        scrollRight()

        const displaySize = parseInt(getComputedStyle(displayRef.current).getPropertyValue('width'))
        
        const boxes = Math.round(displaySize/200)

        setBoxSize(displaySize/boxes)
    }

    useEffect(() => {
        getBoxSize()

        window.addEventListener('resize', () => getBoxSize())

        return () => window.removeEventListener('resize', () => getBoxSize())
    },[])

    return(
        <Container>
            <DoneCallsArrow direction={'left'} onClick={(dir) => handleScroll(dir)}/> 
            <Display ref={displayRef}>
                {
                    attendedCalls === null ? 
                    <Row height={100} align={'center'} justify={'center'}>
                        <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                    </Row>
                    :
                    (
                        attendedCalls.length === 0 ? 
                        <Column align={'center'} justify={'center'}>
                            <Text weight={500} color={'#aaa'}>Nenhum atendimento</Text>
                            <Text weight={500} color={'#aaa'}>realizado</Text>
                        </Column>
                        :
                        <Boxes>
                            {
                                attendedCalls.sort(sortByTime).map((e,i) => {
                                    return <DoneCallsBox key={i} call={e} size={boxSize} onShowAnnotation={(id) => onShowAnnotation(id)}/>
                                })
                            }
                        </Boxes>
                    )
                }
            </Display>
            <DoneCallsArrow direction={'right'} onClick={(dir) => handleScroll(dir)}/> 
        </Container>
    )
}

export default DoneCallsDisplay