import { Card, Spacer } from '../../layout/Layout'
import { Row, Column, Text, Icon } from '../../layout/'
import FilaCheckIcon from '../../img/icons/FilaCheckIcon'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useContext, useState } from 'react'
import { StatsContext } from '../../contexts/StatsProvider'
import PulseLoader from "react-spinners/PulseLoader"

function DoneCalls(){

    const [index, setIndex] = useState(0)
    
    const { stats } = useContext(StatsContext)

    const handleScroll = (direction) => {
        if(direction === 'left'){
            if(index === 0) return

            setIndex(index - 1)
        }

        else if(direction === 'right'){
            if(index === stats.done?.length-3) return

            setIndex(index + 1)
        }
    }
    
    return(
        <Card align={'center'} justify={'center'}>
            <FilaCheckIcon/>
            <Text>Total de</Text>
            <Text>Atendimentos</Text>
            <Spacer height={5}/>
                { 
                    stats === null ? 
                    <Row height={40} align={'center'} justify={'center'}>
                        <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                    </Row>
                    :
                    <Row align={'center'} height={40}>
                        {
                            stats.done?.length > 3 &&
                            <Icon pointer margin={0.01} color={'#aaa'} hoverColor={'#00BFA5'} onClick={() => handleScroll('left')}>
                                <MdKeyboardArrowLeft/>
                            </Icon>
                        }
                        {
                            stats.done === null ? 
                            <Column align={'center'} justify={'center'}>
                                <Text weight={500} color={'#aaa'}>Nenhum atendimento</Text>
                                <Text weight={500} color={'#aaa'}>realizado</Text>
                            </Column>
                            :
                            <Row height={100} justify={'space-around'}>
                                {
                                    stats.done?.map((e,i) => {
                                        if(i < index+3 && i >= index){
                                            return(   
                                                <Column key={i} align={'center'} width={100} height={'fit-content'} leftLine={i > 0}>
                                                    <Text size={2} weight={800} color={e.prior ? '#FB9800' : e.prior === false ? '#00BFA5' : '#004D40'}>{e.done}</Text>
                                                    <Text size={0.9} weight={300}>{e.label}</Text>
                                                </Column>
                                            )
                                        }
                                    })
                                }
                            </Row>
                        }
                        {
                            stats.done?.length > 3 &&
                            <Icon pointer margin={0.01} color={'#aaa'} hoverColor={'#00BFA5'} onClick={() => handleScroll('right')}>
                                <MdKeyboardArrowRight/>
                            </Icon>
                        }
                    </Row>
                }    
        </Card>
    )
}

export default DoneCalls