import styled from "styled-components";

export const PageContent = styled.div`
    display: flex;
    width: 100%;
    height: 88%;
    padding: 2rem;
`

export const Dashboard = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 3fr 3fr 3fr 1.5fr;
    grid-gap: 1.5rem;
    width: 100%;
    height: 100%;
`

export const Grid = styled.div`
    grid-column: ${props => props.col};
    grid-row: ${props => props.row};
`