import { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthProvider'
import { CallsContext } from './CallsProvider'
import { filterCallsByAttendant, filterCallsByStatus, countByType, calculateAverageTime, getAllTypes } from '../services/stats'
import _ from 'lodash'

export const StatsContext = createContext({})

export const StatsProvider = ({children}) => {

    const { user } = useContext(AuthContext)
    const { calls } = useContext(CallsContext)
    
    const [stats, setStats] = useState(null)

    const getStats = () => {

        if(calls === null) return
        
        const types = getAllTypes(calls)
        let done = [], waiting = [], totalDone = 0, totalWaiting = 0

        types[0].forEach((type, idx) => {
            const count = countByType(filterCallsByAttendant(filterCallsByStatus(calls, 'finished'), user?._id), type)
            const stat = {label: type, done: count, prior: types[1][idx]}
            totalDone += count
            done.push(stat)
        })

        types[0].forEach((type, idx) => {
            const count = countByType(filterCallsByStatus(calls, 'waiting'), type)
            const stat = {label: type, waiting: count, prior: types[1][idx]}
            totalWaiting += count
            waiting.push(stat)
        })

        const average = calculateAverageTime(filterCallsByAttendant(filterCallsByStatus(calls, 'finished'), user?._id))

        if(totalDone > 0){
            done.unshift({label: 'Total', done: totalDone, prior: null})
        }
        else done = null

        if(totalWaiting > 0){
            waiting.unshift({label: 'Total', waiting: totalWaiting, prior: null})
        }
        else waiting = null

        setStats({done, waiting, average})
    }

    useEffect(() => {
        getStats()
    },[calls])

    return(
        <StatsContext.Provider value={{stats}}>
            {children}
        </StatsContext.Provider>
    )
}