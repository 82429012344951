import styled, { keyframes } from "styled-components";
import { HiArrowRight } from 'react-icons/hi'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import PuffLoader from "react-spinners/PuffLoader"

const BounceClick = keyframes`
    0%{
        transform: translate(0, 0);
    }

    50%{
        transform: translate(10px, 0);
    }

    100%{
        transform: translate(0, 0);
    }
`

const Click = keyframes`
    0%{
        transform: scale(0);
        opacity: 1;
    }

    100%{
        transform: scale(1);
        opacity: 0;
    }
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 2rem;
`

const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    position: absolute;
    cursor: pointer;
    transition: all 0.5s;

    svg{
        color: ${props => props.color};
        font-size: 3rem;
        position: absolute;
    }

    &:hover{
        transform: ${props => props.animate && 'scale(1.1)'};
    }

    &:active{
        animation: ${props => props.animate && BounceClick} 0.2s 1;
    }
`

const Circle = styled.div`
    display: ${props => props.pulse ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
    position: absolute;
    cursor: pointer;
    background: #00BFA5;
    animation: ${props => props.pulse && Click} 1.5s infinite;

    &::before{
        content: '';
        height: 6rem;
        width: 6rem;
        border-radius: 100%;
        background: #00BFA5;
        position: absolute;
        animation: ${Click} 1.5s infinite;

    }

    &::after{
        content: '';
        height: 8rem;
        width: 8rem;
        border-radius: 100%;
        background: #00BFA5;
        position: absolute;
        animation: ${Click} 1.5s infinite;
    }
    
`

function NextButton({status, active, pulse, loading, onClick}){
    
    return(
        <Tooltip theme="light" arrow delay={800} position={'top'} html={'Chamar a próxima senha'}>
            <Wrapper>
                {
                    loading ?
                    <PuffLoader color={'#00BFA5'} loading={true} size={32}/>
                    :
                    <>
                        <Circle pulse={pulse}/>
                        <Button animate={active} color={active ? status ? '#00BFA5' : '#F34236' : '#bbb'} onClick={() => onClick()}>
                            <HiArrowRight/>
                        </Button>
                    </>
                }
            </Wrapper>
        </Tooltip>
    )
}

export default NextButton