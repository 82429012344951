import { Page, Header } from '../../layout/Layout'
import { PageContent, Dashboard, Grid } from './style'
import User from '../../components/User'
import Controller from '../../components/controller/Controller'
import AverageTime from '../../components/dashboard/AverageTime'
import DoneCalls from '../../components/dashboard/DoneCalls'
import WaitingCalls from '../../components/dashboard/WaitingCalls'
import QueueTable from '../../components/dashboard/queue/QueueTable'
import DoneCallsDisplay from '../../components/dashboard/doneCallsDisplay/DoneCallsDisplay'
import { useState, useContext } from 'react'
import ModalBackground from '../../components/modal/ModalBackground'
import CancelCallConfirmationBox from '../../components/modal/CancelCallConfirmationBox'
import NewAnnotationBox from '../../components/modal/NewAnnotationBox'
import AnnotationBox from '../../components/modal/AnnotationBox'
import CallRandomConfirmationBox from '../../components/modal/CallRandomConfirmationBox'
import LogoutConfirmation from '../../components/modal/LogoutConfirmation'
import NotifBox from '../../components/notification/NotifBox'
import { AttendanceContext } from '../../contexts/AttendanceProvider'
import { toast } from 'react-toastify';
import { update } from '../../API/queries';
import { ConfigContext } from '../../contexts/ConfigProvider'
import { AuthContext } from '../../contexts/AuthProvider'
// import RatingInput from '../../components/ratingInput'
// import RatingTerminal from '../../components/ratingTerminal'

function Main(){

    const { callRandom, status } = useContext(AttendanceContext)
    const { notify } = useContext(ConfigContext)
    const { authenticated } = useContext(AuthContext)

    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false)
    const [doLogout, setDoLogout] = useState(false)

    const [showCancelConfirmationBox, setShowCancelConfirmationBox] = useState(false)
    const [showCallRandomConfirmationBox, setShowCallRandomConfirmationBox] = useState(false)
    const [showAddAnnotationBox, setShowAddAnnotationBox] = useState(false)
    const [showAnnotationBox, setShowAnnotationBox] = useState(false)

    const [callId, setCallId] = useState(null)
    const [call, setCall] = useState(null)

    const cancelCall = (id) => {

        const cancelOpts = {
            decrement: true,
            increment: false,
            returnDocument: 'before',
            alert: false,
            options: {
                index: null,
                status: "canceled",
                canceled_at: null
            }
        }

        cancelOpts.options.canceled_at = Date.now()

        toast.promise(
            update('calls', id, cancelOpts).then(res => {
                console.log(res.data)
            }),
            {
                pending: 'Cancelando senha...',
                success: 'Senha cancelada com sucesso',
                error: 'Não foi possível cancelar a senha',
            },{containerId: 'alert'}
    )}

    const handleCloseAnnotationBox = () => {
        setCallId(null)
        setShowAnnotationBox(false)
        setShowAddAnnotationBox(false)
    }

    const handleAddAnnotation = (id) => {
        setCallId(id)
        setShowAddAnnotationBox(true)
    }

    const handleShowAnnotation = (id) => {
        setCallId(id)
        setShowAnnotationBox(true)
    }

    const handleCancelConfirmation = (call) => {
        setCall(call)
        setShowCancelConfirmationBox(true)
    }

    const handleCancelCall = () => {
        setShowCancelConfirmationBox(false)
        cancelCall(call._id)
    }

    const handleCallRandomConfirmation = (call) => {
        setCall(call)
        setShowCallRandomConfirmationBox(true)
    }

    const handleCallRandom = () => {
        setShowCallRandomConfirmationBox(false)

        if(status === 'attending' || status === 'waiting'){
            toast.error('Você deve finalizar o atendimento antes de chamar a próxima senha', {containerId: 'alert'})
            return
        }

        callRandom(call)
    }

    return(
        <Page>
            <Header>
                <User onLogout={() => setShowLogoutConfirmation(true)} doLogout={doLogout}/>
                <Controller onAddAnnotation={(id) => handleAddAnnotation(id)}/>
            </Header>
            <PageContent>
                {
                    authenticated &&
                    <>
                        <Dashboard>
                            <Grid col={'1/2'} row={'1/2'}>
                                <DoneCalls/>
                            </Grid>
                            <Grid col={'1/2'} row={'2/3'}>
                                <WaitingCalls/>
                            </Grid>
                            <Grid col={'1/2'} row={'3/4'}>
                                <AverageTime/>
                            </Grid>
                            <Grid col={'2/3'} row={'1/4'}>
                                <QueueTable onCancel={(call) => handleCancelConfirmation(call)} onCallRandom={(call) => handleCallRandomConfirmation(call)}/>
                            </Grid>
                            <Grid col={'1/3'} row={'4/5'}>
                                <DoneCallsDisplay onShowAnnotation={(id) => handleShowAnnotation(id)}/>
                            </Grid>
                        </Dashboard>    
                        {notify && <NotifBox/>}
                    </>
                }
            </PageContent>
            {/* <RatingInput/> */}
            {/* <RatingTerminal/> */}
            <ModalBackground show={showCancelConfirmationBox} element={<CancelCallConfirmationBox call={call} onConfirm={() => handleCancelCall()} onCancel={() => setShowCancelConfirmationBox(false)}/>}/>
            <ModalBackground show={showLogoutConfirmation} element={<LogoutConfirmation onConfirm={() => setDoLogout(true)} onCancel={() => setShowLogoutConfirmation(false)}/>}/>
            <ModalBackground show={showCallRandomConfirmationBox} element={<CallRandomConfirmationBox call={call} onConfirm={() => handleCallRandom()} onCancel={() => setShowCallRandomConfirmationBox(false)}/>}/>
            <ModalBackground show={showAnnotationBox} element={<AnnotationBox callId={callId} onClose={() => handleCloseAnnotationBox()}/>}/>
            <ModalBackground show={showAddAnnotationBox} element={<NewAnnotationBox callId={callId} onClose={() => handleCloseAnnotationBox()}/>}/>
        </Page>
    )
}

export default Main