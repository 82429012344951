import { Row, Column, Text, Icon } from '../../layout'
import { IoWarningOutline } from 'react-icons/io5'
import { BiLogIn, BiLogOut } from 'react-icons/bi'
import { RiUserShared2Line } from 'react-icons/ri'

const getColor = (type) => {
    //if(notification.urgent) return '#F34236'
    if(type === 'warning') return '#f1c40f'
    else return '#07bc0c'
}

export const getType = (type) => {
    switch(type){
        case 'warning': return 'warning'
        case 'login': return  'success'
        case 'logout': return 'success'
        case 'attendance': return 'success'
        default: break
    }
}

export const getIcon = (type) => {
    switch(type){
        case 'warning': return <Icon margin={'0'} color={getColor(type)} size={2}><IoWarningOutline/></Icon>
        case 'login': return <Icon margin={'0'} color={getColor(type)} size={2}><BiLogIn/></Icon>
        case 'logout': return <Icon margin={'0'} color={getColor(type)} size={2}><BiLogOut/></Icon>
        case 'attendance': return <Icon margin={'0'} color={getColor(type)} size={2}><RiUserShared2Line/></Icon>
        default: break
    }
}

const getTitle = (type) => {
    switch(type){
        case 'warning': return 'Aviso'
        case 'login': return 'Login'
        case 'logout': return 'Logout'
        case 'attendance': return 'Atendimento'
        default: break
    }
}

export const NotifToast = ({notification}) => {
    
    return<>
        <Text weight={600} bottom={5}>{getTitle(notification.type)}</Text>
        <Text wrap={'normal'}>{notification.message}</Text>
    </>
                
            
}