import _ from 'lodash'

export const filterCallsByAttendant = (calls, attendant) => {
    return calls.filter(call => call.attendant_id === attendant)
}

export const filterCallsByStatus = (calls, status) => {
    return calls.filter(call => call.status === status)
}

export const calculateAverageTime = (calls) => {
    let times = []

    calls.forEach(call => times.push(call.finished_at - call.attended_at))

    return times.reduce((a, b) => a + b, 0) / times.length;
}

export const getAllTypes = (calls) => {
    let types = [], priors = []

    calls.forEach(call => {
        if(!types.includes(call.type)) {
            types.push(call.type)
            priors.push(call.prior)
        }
    })

    return [types, priors]
}

export const countByType = (calls, type) => {
    let count = 0

    calls.forEach(call => {
        if(call.type === type) count += 1
    })

    return count
}