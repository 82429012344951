import styled from "styled-components"
import { Text } from "../../../layout"
import { DeskContext } from "../../../contexts/DeskProvider"
import { AuthContext } from "../../../contexts/AuthProvider"
import { useContext } from "react"
import _ from 'lodash'
import AnnotationIcon from "./AnnotationIcon"
import { useState, useEffect } from "react"

const Box = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.size}px;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 0;
    border-left: 1px solid ${props => props.user ? '#00897B' : '#ddd'};
    margin-left: auto;
    background-color: ${props => props.user ? '#00BFA5' : 'white'};
    position: relative;
    overflow: visible;

    &:hover{
        background-color: ${props => props.user ? '#00897B' : '#eee'};
    }
`

function DoneCallsBox({call, size, onShowAnnotation}){

    const { desks } = useContext(DeskContext)
    const { user } = useContext(AuthContext)

    const [hasAnnotation, setHasAnnotation] = useState(false)

    useEffect(() => {
        if(Object.keys(call).includes('annotation')) setHasAnnotation(true)
    },[call])
    
    const getDeskName = (id) => {
        return desks.find(desk => desk._id === id)?.name
    }

    const getColor = () => {
        const obj = {}

        if(call.attendant_id === user._id && call.prior) _.assign(obj, {label: 'white', password: '#FB9800', icon: 'white'})
        if(call.attendant_id === user._id && !call.prior) _.assign(obj, {label: 'white', password: 'white', icon: 'white'})
        if(call.attendant_id !== user._id && call.prior) _.assign(obj, {label: '#424242', password: '#FB9800', icon: '#00BFA5'})
        if(call.attendant_id !== user._id && !call.prior) _.assign(obj, {label: '#424242', password: '#00BFA5', icon: '#00BFA5'})
        
        return obj
    }

    return(
        <Box user={call.attendant_id === user._id} size={size}>
            <Text size={0.9} color={getColor().label}>{getDeskName(call.desk_id)}</Text>                
            <Text size={1.5} weight={700} color={getColor().password}>{call.password}</Text>                
            <Text size={0.9} color={getColor().label}>{new Date(call.attended_at).toLocaleString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})}</Text>                
            {
                hasAnnotation && <AnnotationIcon color={getColor().icon} onShow={() => onShowAnnotation(call._id)}/>
            }
        </Box>
    )
}

export default DoneCallsBox