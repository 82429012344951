import { createContext, useEffect, useState, useRef, useContext } from 'react'
import { pauseAttendant, unpauseAttendant, disconnectAttendant, connectAttendant, update } from '../API/queries'
import { toast } from 'react-toastify';
import { ConfigContext } from './ConfigProvider';

export const AuthContext = createContext({})

export const AuthProvider = ({children}) => {

    const { setConfig } = useContext(ConfigContext)
    
    const [authenticated, setAuthenticated] = useState(false)
    const [token, setToken] = useState(null)
    const [user, _setUser] = useState(null)
    const [business, _setBusiness] = useState(null)
    const [place, _setPlace] = useState(null)
    const [desk, _setDesk] = useState(null)
    const [userStatus, setUserStatus] = useState(false)

    const deskIdRef = useRef()
    const userIdRef = useRef(null)

    const setUser = (data) => {
      _setUser(data)
      userIdRef.current = data._id
      sessionStorage.setItem('user', JSON.stringify(data))
    }

    const setBusiness = (data) => {
      _setBusiness(data)
      sessionStorage.setItem('business', JSON.stringify(data))
    }

    const setPlace = (data) => {
      _setPlace(data)
      sessionStorage.setItem('place', JSON.stringify(data))
    }
    
    const setDesk = (data) => {
      _setDesk(data)
      deskIdRef.current = data._id
      sessionStorage.setItem('desk', JSON.stringify(data))
    }
    
    const connect = (desk_id, user_id) => {
      connectAttendant({desk_id, user_id}).then(res => {
        if(res.status === 200){ 
          setUser(res.data.user)
          setBusiness(res.data.business)
          //setPlace(res.data.place)
          setDesk(res.data.desk)
          setConfig(res.data.config)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  
    useEffect(() => {
      recoverParams()

      const _disconnect = async () => await disconnectAttendant({desk_id: deskIdRef.current, user_id: userIdRef.current})

      window.addEventListener('beforeunload', _disconnect);

      return () => {
        window.removeEventListener('beforeunload', _disconnect);
      }
    },[])
    
    const restoreData = () => {
      const recoveredToken = sessionStorage.getItem('token')
      const recoveredUser = JSON.parse(sessionStorage.getItem('user'))
      const recoveredBusiness = JSON.parse(sessionStorage.getItem('business'))
      const recoveredPlace = JSON.parse(sessionStorage.getItem('place'))
      const recoveredDesk = JSON.parse(sessionStorage.getItem('desk'))

      if(recoveredToken && recoveredUser && recoveredBusiness && recoveredPlace && recoveredDesk){
        setToken(JSON.parse(recoveredToken))
        setUser(recoveredUser)
        setBusiness(recoveredBusiness)
        setPlace(recoveredPlace)
        setDesk(recoveredDesk)
        return
      }

      window.location.replace('https://login.phila.com.br')
    }

    const recoverParams =  () => { 
      const params = new URLSearchParams(window.location.search)
      const _token = params.get('token')
      const userId = params.get('userId')
      const attendantId = params.get('attendantId')
      const deskId = params.get('deskId')
      const place = params.get('place')
      
      if(_token && userId && attendantId && place){ 
        setToken(_token)
        setPlace(place)
        sessionStorage.setItem('token', JSON.stringify(_token))
        
        connect(deskId, attendantId)

        return
      }
 
      restoreData()
    }

    useEffect(() => {
      if(token && user && business && place && desk && !authenticated){
        setAuthenticated(true)
      }
    },[token, user, business, place, desk])

    useEffect(() => {
      if(user) setUserStatus(user?.status)
    },[user])

    const logout =  () => {
      disconnectAttendant({desk_id: desk._id, user_id: user._id}).then((res) => {
          if(res.status === 200) window.location.replace('https://login.phila.com.br')
      }).catch(err => {
        toast.error(`Não foi possível completar a ação. Erro: ${err}`, {containerId: 'alert'})
      })
    }

    const pause = (desk) => {
        pauseAttendant({desk_id: desk._id, user_id: user._id}).then((res) => {
          setUser(res.data.user)
          toast.success('Seu atendimento foi pausado', {containerId: 'alert'})
        }).catch(err => {
          toast.error(`Não foi possível completar a ação. Erro: ${err}`, {containerId: 'alert'})
        })
    }

    const unpause = (desk) => {
        unpauseAttendant({desk_id: desk._id, user_id: user._id}).then((res) => {
          setUser(res.data.user)
          toast.success('Seu atendimento foi iniciado novamente', {containerId: 'alert'})
        }).catch(err => {
          toast.error(`Não foi possível completar a ação. Erro: ${err}`, {containerId: 'alert'})
        })
    }

    const forceDisconnect = () => {
      sessionStorage.clear()
      window.location.replace('https://login.phila.com.br')
    }

    const updateOperatingStatus = async (operating_status) => {
      try {
        await update('desk', deskIdRef.current, {operating_status})
        
      } catch (error) {
        console.log(error)
      }
    }

    return (
        <AuthContext.Provider value={{authenticated, token, business, user, setUser, place, desk, userStatus, logout, pause, unpause, forceDisconnect, updateOperatingStatus}}>
            {children}
        </AuthContext.Provider>
    )
}