import { Card, Spacer } from '../../layout/Layout'
import { Row, Column, Text, Icon } from '../../layout/'
import FilaClockIcon from '../../img/icons/FilaClockIcon'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useState, useContext } from 'react'
import { StatsContext } from '../../contexts/StatsProvider'
import PulseLoader from "react-spinners/PulseLoader"

function WaitingCalls(){

    const { stats } = useContext(StatsContext)

    const [index, setIndex] = useState(0) 

    const handleScroll = (direction) => {
        if(direction === 'left'){
            if(index === 0) return

            setIndex(index - 1)
        }

        else if(direction === 'right'){
            if(index === stats.waiting.length-3) return

            setIndex(index + 1)
        }
    }
    
    return(
        <Card align={'center'} justify={'center'}>
            <FilaClockIcon/>
            <Text>Aguardando</Text>
            <Text>Atendimento</Text>
            <Spacer height={5}/>
            {
                stats === null ? 
                <Row height={40} align={'center'} justify={'center'}>
                    <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                </Row>
                :
                <Row align={'center'} height={40}>
                    {
                        stats.waiting?.length > 3 &&
                        <Icon pointer margin={0.01} color={'#aaa'} hoverColor={'#00BFA5'} onClick={() => handleScroll('left')}>
                            <MdKeyboardArrowLeft/>
                        </Icon>
                    }
                    {
                        stats.waiting === null ? 
                        <Column align={'center'} justify={'center'}>
                            <Text weight={500} color={'#aaa'}>Nenhuma senha</Text>
                            <Text weight={500} color={'#aaa'}>aguardando</Text>
                        </Column>
                        :
                        <Row height={100} justify={'space-around'}>
                            {
                                stats.waiting?.map((e,i) => {
                                    if(i < index+3 && i >= index){
                                        return(   
                                            <Column key={i} align={'center'} width={100} height={'fit-content'} leftLine={i > 0}>
                                                <Text size={2} weight={800} color={e.prior ? '#FB9800' : e.prior === false ? '#00BFA5' : '#004D40'}>{e.waiting}</Text>
                                                <Text size={0.9} weight={300}>{e.label}</Text>
                                            </Column>
                                        )
                                    }
                                })
                            }
                        </Row>
                    }
                    {
                        stats.waiting?.length > 3 &&
                        <Icon pointer margin={0.01} color={'#aaa'} hoverColor={'#00BFA5'} onClick={() => handleScroll('right')}>
                            <MdKeyboardArrowRight/>
                        </Icon>
                    }
                </Row>
            }
        </Card>
    )
}

export default WaitingCalls