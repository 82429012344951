import styled from 'styled-components'
import { Text, Icon, Row, Column } from '../../layout'
import { BsX } from 'react-icons/bs'
import { BiCommentDots } from 'react-icons/bi'
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'
import { useState, useEffect } from 'react'
import { getByID } from '../../API/queries'
import PulseLoader from "react-spinners/PulseLoader"

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    height: 30vh;
    min-height: 230px;
    width: 30vw;
    min-width: 400px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 1.5rem;
`

const Annotation = styled.div`
    display: flex;
    align-items: top;
    justify-content: center;
    max-height: calc(100% - 4rem);
    overflow-y: auto;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #ddd;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #bbb;
    }
`

export default function AnnotationBox({callId, onClose}){

    const [annotation, setAnnotation] = useState(null)
    const [attendantName, setAttendantName] = useState(null)
    const [loading, setLoading] = useState(true)

    const getCallAnnotation = () => {
        getByID('callsById', callId).then((res) => {
            console.log(res)
            setAnnotation(res.data.annotation)
            getAttendantName(res.data.attendant_id)
        })
    }

    const getAttendantName = (id) => {
        getByID('attendant', id).then((res) => {
            setAttendantName(res.data.name)
        })
    }

    useEffect(() => {
        console.log(callId)
        getCallAnnotation()
    },[])

    useEffect(() => {
        if(attendantName !== null) setLoading(false)
    },[attendantName])
    
    return(
        <Box>
            {
                loading ? 
                <Column height={'100%'} width={100} align={'center'} justify={'center'}>
                    <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                </Column>  
                :
                <Column height={'100%'} width={100}>
                    <Row height={30} align={'center'}>
                        <Icon size={2} color={'#00BFA5'}>
                            <BiCommentDots/>
                        </Icon>
                        <Column>
                            <Text weight={600}>{attendantName}</Text>
                            <Text weight={400}>comentou sobre este atendimento:</Text>
                        </Column>
                        <Row justify={'flex-end'} align={'flex-start'}>
                            <Icon pointer color={'#aaa'} hoverColor={'#F34236'} onClick={() => onClose()}>
                                <BsX/>
                            </Icon>
                        </Row>
                    </Row>
                    <Column height={'70%'} align={'center'} justify={'center'}>
                        <Row height={'2rem'} align={'center'}>
                            <Icon size={1.8}>
                                <RiDoubleQuotesL/>
                            </Icon>
                        </Row>
                        <Annotation>
                            <Text wrap align={'center'}>{annotation}</Text>
                        </Annotation>
                        <Row height={'2rem'} align={'center'} justify={'flex-end'}>
                            <Icon size={1.8}>
                                <RiDoubleQuotesR/>
                            </Icon>
                        </Row>
                    </Column>
                </Column>
            }
        </Box>
    )
}