import styled from "styled-components";
import { BiCommentAdd } from 'react-icons/bi'
import { Text, Row, Column, Icon } from '../../layout'
import { BsCheck, BsX } from 'react-icons/bs'
import { useState } from "react";
import { toast } from 'react-toastify';
import { getByID, update } from '../../API/queries'
import { useRef } from "react";
import { useEffect } from "react";
import PulseLoader from "react-spinners/PulseLoader"

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    height: 30vh;
    min-height: 230px;
    width: 30vw;
    min-width: 400px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 1.5rem;
`

const Textarea = styled.textarea`
    resize: none;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    outline: none;
    padding: 10px;

    &:focus{
        border: 1px solid #00BFA5;
    }
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 2.4rem;
    border: 1px solid #424242;
    border-radius: 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    background-color: white;
    margin-left: 1rem;
    color: #424242;
    cursor: pointer;

    svg{
        font-size: 2rem;
        color: #00BFA5;
    }

    &:hover{
        border: 1px solid #00BFA5;
    }

    &:active{
        background-color: #00BFA5;
        color: white;

        svg{
            color: white;
        }
    }
`

export default function NewAnnotationBox({callId, onClose}){

    const [annotation, setAnnotation] = useState('')
    const [isNew, setIsNew] = useState(null)
    const [call, setCall] = useState(null)
    const [loading, setLoading] = useState(true)

    const textAreaRef = useRef(null)

    const addAnnotation = () => {
        if(annotation === '') {
            toast.error('Insira o comentário antes de adicioná-lo', {containerId: 'alert'})
            return
        }

        toast.promise(
            update('calls', callId, {options: {annotation}}).then(() => {
                onClose()
            }),
            {
                pending: 'Adicionando comentário..',
                success: 'Comentário adicionado com sucesso!',
                error: 'Erro: Não foi possível adicionar o comentário'
            }, {containerId: 'alert'}
        )   
    }

    useEffect(() => {
        getByID('calls', callId).then(res => {
            setCall(res.data)
            console.log(res.data)
        })
        //if(Object.keys(call).includes('annotation')) textAreaRef.current.value = 'rafa'
    },[])

    useEffect(() => {
        if(call !== null) setLoading(false)
    },[call])

    useEffect(() => {
        if(!loading && Object.keys(call).includes('annotation')){
            textAreaRef.current.value = call.annotation
            setIsNew(false)
        }
        else setIsNew(true)
    }, [loading])
    
    return(
        <Box>
            {
                loading ? 
                <Column height={'100%'} width={100} align={'center'} justify={'center'}>
                    <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                </Column>                
                :
                <>
                <Row height={20} align={'center'}>
                    <Row align={'center'}>
                        <Icon color={'#00BFA5'}>
                            <BiCommentAdd/>
                        </Icon>
                        <Text size={1.2}>{isNew ? 'Adicionar comentário' : 'Editar Comentário'}</Text>
                    </Row>
                    <Row justify={'flex-end'}>
                        <Icon pointer color={'#aaa'} hoverColor={'#F34236'} onClick={() => onClose()}>
                            <BsX/>
                        </Icon>
                    </Row>
                </Row>
                <Row height={40} align={'center'}>
                    <Textarea ref={textAreaRef} placeholder="Escreva seu comentário" onChange={(e) => setAnnotation(e.target.value)}/>
                </Row>
                <Row height={20} align={'center'} justify={'flex-end'}>
                    <Button onClick={() => addAnnotation()}>
                        <BsCheck/>
                        {isNew ? 'Adicionar' : 'Confirmar'}
                    </Button>
                </Row>
                </>
            }
        </Box>
    )
}