import styled from "styled-components"
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos} from 'react-icons/md'

const Arrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 3.5rem;
    font-size: 2rem;
`

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 50%;

    svg{
        font-size: 1.5rem;
    }
    
    &:hover{
        background-color: #26A69A;
        
        svg{
            color: white;
        }
    
    }

    &:active{
        background-color: #00897B;
        
        svg{
            color: white;
        }
    }
`

function DoneCallsArrow({direction, onClick}){
    return(
        <Arrow>
            <Circle onClick={() => onClick(direction)}>
                {
                    direction === 'left' ? <MdOutlineArrowBackIos/>
                    :
                    direction === 'right' && <MdOutlineArrowForwardIos/>
                }
            </Circle>
        </Arrow>
    )
}

export default DoneCallsArrow