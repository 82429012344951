import { Card, Column } from '../../../layout/Layout'
import { Row, Text, Table, Select, Option, Icon, Spacer } from '../../../layout/'
import QueueTableRow from './QueueTableRow'
import { TbFilter } from 'react-icons/tb'
import { CallsContext } from '../../../contexts/CallsProvider'
import FilaIcon from '../../../img/icons/FilaIcon'
import { useState, useEffect, useContext } from 'react'
import PulseLoader from "react-spinners/PulseLoader"

function QueueTable({onCancel, onCallRandom}){

    const { calls, queue } = useContext(CallsContext)
    
    const [typeOpts, setTypesOpts] = useState([])
    const [filter, setFilter] = useState('Todos')
    const [nextIndex, setNextIndex] = useState()
    
    const getTypesOpts = () => {
        let opts = ['Todos']
        
        queue.forEach(call => {
            if(!opts.includes(call.type)) opts.push(call.type)
        })

        setTypesOpts(opts)
    }
    
    // const getPosition = () => {
    //     const indexes = []
        
    //     queue.forEach(queue => indexes.push(queue.index))
        
    //     setNextIndex(Math.min(...indexes))
    // }

    useEffect(() => {
        if(queue !== null) {
            getTypesOpts()
            //getPosition()
        }
    },[queue])

    return(
        <Card>  
            <Row height={15} align={'center'} bottom={1} left={1} right={1}>
                <FilaIcon/>
                <Spacer width={0.5}/>
                <Column>
                    <Text size={1.2} weight={300}>Fila de Senhas</Text>
                </Column>
                <Column width={'fit-content'}>
                    <Row align={'center'}> 
                        <Icon size={1.7} color={'#878787'}>
                            <TbFilter/>
                        </Icon>
                        <Select width={13} value={filter} onChange={(e) => setFilter(e.target.value)}>
                            {
                                typeOpts.map((e,i) => {
                                    return <Option key={i}>{e}</Option>
                                })
                            }
                        </Select>
                    </Row>
                </Column>
            </Row>
            <Row height={5} align={'center'} borderBtmStyle={'solid'} borderBtmWidth={1} left={1}>
                <Column width={10}>
                    <Text weight={600}>Senha</Text>
                </Column>
                <Column align={'center'} width={13}>
                    <Text weight={600}>Serviço</Text>
                </Column>
                <Column align={'center'} width={13}>
                    <Text weight={600}>Tipo</Text>
                </Column>
                <Column align={'center'} width={15}>
                    <Text weight={600}>Emitida às</Text>
                </Column>
                <Column align={'center'} width={12}>
                    <Text weight={600}>Posição</Text>
                </Column>
                <Column align={'center'} width={20}>
                    <Text weight={600}>Tempo em espera</Text>
                </Column>
                <Column align={'center'} width={15}>
                    <Text weight={600}>Ações</Text>
                </Column>
            </Row>
            {
                calls === null && queue === null ? 
                <Column height={100} align={'center'} justify={'center'}>
                    <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                </Column>
                : 
                (
                    queue?.length === 0 ? 
                    <Column height={100} align={'center'} justify={'center'}>
                        <Text weight={500} color={'#aaa'}>Não há nenhuma</Text>
                        <Text weight={500} color={'#aaa'}>senha nesta Fila</Text>
                    </Column>
                    :
                    <Table>
                        {
                            queue?.map((e,i) => {
                                if(filter === 'Todos') return <QueueTableRow key={i} call={e} isNext={e.index === nextIndex} index={i} onCancel={onCancel} onCallRandom={onCallRandom}/> 
                                else if(e.type === filter) return <QueueTableRow key={i} call={e} isNext={e.index === nextIndex} index={i} onCancel={onCancel} onCallRandom={onCallRandom}/> 
                            })
                        }
                    </Table>
                )
            }
        </Card>
    )
}

export default QueueTable