import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    z-index: 999999;
    animation: ${props => props.show ? 'fadeIn' : 'fadeOut'} 0.5s;
    animation-fill-mode: forwards;

    @keyframes fadeIn {
        from { background-color: rgba(0,0,0,0); }
        to { background-color: rgba(0,0,0,0.5); }
    }

    @keyframes fadeOut {
        from { background-color: rgba(0,0,0,0.5); }
        to { background-color: rgba(0,0,0,0); }
    }
`

export default function ModalBackground({show, element}){

    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if(show) setShowModal(true)
        else{
            setTimeout(() => {
                setShowModal(false)
            }, 500);
        }
    },[show])
    
    return(
        <>
            {   
                showModal &&
                <ModalScreen show={show}>
                    {show && element}
                </ModalScreen>
            }
        </>

    )
}