import { ModalBox } from "./ModalBox";
import { Column, Row, Text, Spacer, Icon } from '../../layout'
import { Yes, No } from '../buttons'
import { IoWarningOutline } from 'react-icons/io5'

export default function CancelCallConfirmationBox({call, onConfirm, onCancel}){

    return(
        <ModalBox>
            <Column align={'center'}>
                <Row align={'center'} height={'3rem'}>
                    <Text size={1.1} wrap>{'Deseja cancelar a senha '}</Text>
                    <Spacer width={0.5}/>
                    <Text size={1.2} weight={600} color={call?.prior ? '#FB9800' : '#00BFA5'}>{call?.password}</Text>
                    <Text size={1.1}>{'?'}</Text>
                </Row>
                <Row justify={'center'} align={'center'} height={'3rem'}>
                    <Yes onClick={() => onConfirm()}/>
                    <No onClick={() => onCancel()}/>
                </Row>
                <Spacer height={2}/>
                <Row height={'2rem'} align={'center'} justify={'center'}>
                    <Icon size={2} color={'#FB9800'}>
                        <IoWarningOutline/>
                    </Icon>
                    <Column>
                        <Row height={'1.25rem'} align={'center'}>
                            <Text size={0.9} weight={600}>Aviso:</Text>
                            <Text size={0.9} wrap>{' se esta senha for cancelada'}</Text>
                        </Row>
                        <Row height={'1.25rem'} align={'center'}>
                            <Text size={0.9} wrap>{'não poderá mais ser chamada'}</Text>
                        </Row>
                    </Column>
                </Row>
            </Column>
        </ModalBox>
    )
}