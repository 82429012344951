import { createContext, useState } from 'react'

export const ConfigContext = createContext()

export const ConfigProvider = ({children}) => {

    const [permissions, setPermissions] = useState({})
    const [notify, setNotify] = useState(false)
    const [notifications, setNotifications] = useState({})

    const setConfig = (data) => {
        setPermissions(data?.desk_permissions)
        setNotify(data?.notification.notify_attendants)
        setNotifications(data?.notification)
    }
    
    return(
        <ConfigContext.Provider value={{permissions, notify, notifications, setConfig}}>
            {children}
        </ConfigContext.Provider>
    )
}