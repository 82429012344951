import { Card, Spacer } from '../../layout/Layout'
import { Icon, Text } from '../../layout/'
import { TbClock } from 'react-icons/tb'
import { StatsContext } from '../../contexts/StatsProvider'
import { useContext } from 'react'
import timeFormat from '../../services/timeFormat'
import PulseLoader from "react-spinners/PulseLoader"

function AverageTime(){

    const { stats } = useContext(StatsContext)

    return(
        <Card align={'center'} justify={'center'}>
            <Icon size={2.5} color={'#00BFA5'}>
                <TbClock/>
            </Icon>
            <Text size={1}>Tempo Médio</Text>
            <Text size={1}>de Atendimento</Text>
            <Spacer height={5}/>
            {   
                stats === null ?
                <PulseLoader color={'#00BFA5'} loading={true} size={8}/>
                :
                <Text size={2} weight={800} color={'#00BFA5'}>{timeFormat(stats.average/1000)}</Text>
            }
        </Card>
    )
}

export default AverageTime 