import styled from "styled-components"


export const Row = styled.span`
    display: flex;
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    width: 100%;
    height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'%' : 100+'%')};
    min-height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'%' : 100+'%')};
    padding-top: ${props => props.top}rem;
    padding-bottom: ${props => props.bottom}rem;
    padding-left: ${props => props.left}rem;
    padding-right: ${props => props.right}rem;
    border-bottom-style: ${props => props.borderBtmStyle};
    border-bottom-width: ${props => props.borderBtmWidth}px;
    border-bottom-color: ${props => props.borderBtmColor};
    background-color: ${props => props.color};

    &:hover{
        background-color: ${props => props.hoverColor};
    }
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    margin-left: ${props => props.left}rem;
    margin-right: ${props => props.right}rem;
    padding-bottom: ${props => props.bottom}rem;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'%' : 100+'%')};
    height: ${props => props.height && props.height};
    border-left: ${props => props.leftLine && '1px solid #ccc'};
    border-right: ${props => props.rightLine && '1px solid #ccc'};
`

export const Text = styled.h1`
    font-size: ${props => props.size ? props.size : 1}rem;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#424242'};
    text-align: ${props => props.align && props.align};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.pointer && 'pointer'};
    white-space: ${props => props.wrap ? 'normal' : 'nowrap'};
    text-overflow: ellipsis;
    margin: ${props => props.margin}rem;

    &:hover{
        color: ${props => props.hover && '#1565C0'};
    }
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.margin ? props.margin : 0.5}rem;
    padding-top: ${props => props.top && props.top}rem;
    padding-bottom: ${props => props.bottom && props.bottom}rem;
    padding-left: ${props => props.left && props.left}rem;
    padding-right: ${props => props.right && props.right}rem;
    cursor: ${props => props.pointer && 'pointer'};
    
    svg{
        color: ${props => props.color ? props.color : '#424242'};
        font-size: ${props => props.size ? props.size : 2}rem;
    
        &:hover{
            color: ${props => props.hoverColor};
            font-size: ${props => props.hoverSize}rem;
        }

        &:active{
            color: ${props => props.activeColor};
            font-size: ${props => props.activeSize}rem;
       }
    
    }
`

export const Table = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #ddd;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #bbb;
    }
`

export const Select = styled.select`
    border: 1px solid #424242;
    border-radius: 0.7rem;
    height: 2.1rem;
    width: ${props => props.width ? props.width+'rem' : '50%'};
    outline: none;
    font-style: ${props => props.italic && 'italic'};
    color: ${props => props.color ? props.color : '#424242'};
    margin-left: auto;

    &:focus{
        border: 1px solid #00BFA5;
    }
`

export const Option = styled.option`
    font-style: ${props => props.textStyle && props.textStyle};
    color: ${props => props.color ? props.color : '#424242'};
`

export const Spacer = styled.div`
    margin-top: ${props => props.height}rem;
    margin-left: ${props => props.width}rem;
`

export const Position = styled.div`
    position: absolute;
    top: ${props => props.top}%;
    bottom: ${props => props.bottom}%;
    right: ${props => props.right}%;
    left: ${props => props.left}%;
`

export const Dash = styled.div`
    min-height: 0.4rem;
    min-width: 1.2rem;
    border-radius: 0.25rem;
    margin: 0.65rem;
    background-color: ${props => props.color ? props.color : '#00BFA5'};
`