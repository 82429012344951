import { Column, Text, Dash } from '../../layout/'

function SidePw({title, call}){
    return(
        <Column width={20} align={'center'}>
            <Text>{title}</Text>
            {
                call !== null ? 
                <Text size={1.5} weight={600} color={call?.prior ? '#F9A626' : '#00BFA5'}>{call?.password}</Text>
                :
                <Dash/>
            }
        </Column>
    )
}

export default SidePw