import styled from "styled-components"

export const ModalBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 2rem 3rem;
    z-index: 99999999;
`