import { Section, Column, Space } from '../../layout/Layout'
import { Icon, Position } from '../../layout/'
import MainPw from './MainPw'
import SidePw from './SidePw'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'
import ControlButtons from './ControlButtons'
import { useContext, useEffect } from 'react'
import { AttendanceContext } from '../../contexts/AttendanceProvider'
import { toast } from 'react-toastify';
import { BiCommentAdd } from 'react-icons/bi'
//import RatingAlert from './RatingAlert'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import { useState, useRef } from 'react'


function Controller({onAddAnnotation}){

    const [pulseNext, setPulseNext] = useState(false)
    const pulseTimeout = useRef(0)

    const { previousCall, actualCall, nextCall, status, calling, callNext, startAttendance, finishAttendance, callPrevious, ring/*, allowRatingStatus*/ } = useContext(AttendanceContext)

    const handleCallNext = () => {

        if(status === 'attending') return toast.error('Você deve finalizar o atendimento antes de chamar a próxima senha', {containerId: 'alert'})
            
        if(nextCall === null) return toast.error('Não há nenhuma senha na fila de espera', {containerId: 'alert'})
            
        callNext(nextCall)
    }

    const handlePulseTimeout = () => {
        if(nextCall && status !== 'waiting' && status !== 'attending'){
            pulseTimeout.current = setTimeout(() => {
                setPulseNext(true)
            }, 15000);
        
            return
        }

        setPulseNext(false)
        clearTimeout(pulseTimeout.current)
    }

    useEffect(() => {
        handlePulseTimeout()
    },[status, nextCall])

    const doRing = () => {

        if(status === 'attending') return toast.error('Você já iniciou o atendimento da senha chamada', {containerId: 'alert'})

        if(status !== 'waiting') return toast.error('Não há um senha chamada para ser alertada', {containerId: 'alert'})

        ring(actualCall)
    }
    
    return(
        <Section color={'white'} align={'center'} justify={'center'} width={83} height={100}>
            <Space width={25}/>
            <Column width={20} align={'flex-start'}>
                <PreviousButton status={status !== 'attending'} active={previousCall !== null} onClick={() => callPrevious()}/>
            </Column>
            <SidePw title={'Anterior'} call={previousCall}/>
            <MainPw call={actualCall} status={status} run={true}/>
            <SidePw title={'Próximo'} call={nextCall}/>
            {
                actualCall !== null &&
                <Position right={12}>
                    <Tooltip theme="light" arrow delay={800} position={'top'} html={'Adicionar um comentário à senha atual'}>
                        <Icon pointer size={2.2} color={'#aaa'} hoverColor={'#00BFA5'} onClick={() => onAddAnnotation(actualCall._id)}>
                            <BiCommentAdd/>
                        </Icon>
                    </Tooltip>
                </Position>
            }
            <Column width={20} align={'flex-end'}>
                <NextButton status={status !== 'attending'} active={nextCall !== null} loading={calling} pulse={pulseNext} onClick={() => handleCallNext()}/>
            </Column>
            <Space width={25}/>
            <ControlButtons status={status} onStart={() => startAttendance(actualCall)} onFinish={() => finishAttendance(actualCall)} onRing={() => doRing()}/>
        </Section>
    )
}

export default Controller

