import { ModalBox } from "./ModalBox";
import { Row, Spacer, Text } from '../../layout'
import { Yes, No } from '../buttons'

export default function CallRandomConfirmationBox({call, onConfirm, onCancel}){

    return(
        <ModalBox>
            <Row align={'center'} height={'3rem'}>
                <Text size={1.1} wrap>{'Deseja chamar a senha '}</Text>
                <Spacer width={0.5}/>
                <Text size={1.2} weight={600} color={call?.prior ? '#FB9800' : '#00BFA5'}>{call?.password}</Text>
                <Spacer width={0.5}/>
                <Text size={1.1} wrap>{' fora da ordem da fila?'}</Text>
            </Row>
            <Row justify={'center'} align={'center'} height={'3rem'}>
                <Yes onClick={() => onConfirm()}/>
                <No onClick={() => onCancel()}/>
            </Row>
        </ModalBox>
    )
}